<template>
  <div>
    <div class="mb-4 flex items-center justify-between">
      <h2>Anfragen</h2>
      <router-link
        class="text-white bg-primary-700 hover:bg-primary-800 rounded px-4 py-2"
        to="/anfrage/neu"
      >
        Neue Anfrage
      </router-link>
    </div>

    <div class="grid xl:grid-cols-2 gap-8">
      <dashboard-element>
        <dashboard-head title="Offene Anfragen" />
        <dashboard-table class="p-3">
          <card
            v-for="request in requests"
            v-bind:key="request.id"
            :request="request"
            :status="1"
            @click="$store.state.main.currItem = request"
            @mousedown="$store.state.main.currItem = request"
          />
        </dashboard-table>
      </dashboard-element>
      <dashboard-element>
        <dashboard-head title="Erledigte Anfragen" />
        <dashboard-table class="p-3">
          <card
            v-for="request in requests"
            v-bind:key="request.id"
            :request="request"
            :status="4"
            @click="$store.state.main.currItem = request"
            @mousedown="$store.state.main.currItem = request"
          />
        </dashboard-table>
      </dashboard-element>
    </div>
  </div>
</template>

<script>
import Card from "../components/RequestCard.vue";
import DashboardHead from "../components/dashboard/DashboardHead.vue";
import DashboardTable from "../components/dashboard/DashboardTable.vue";
import DashboardElement from "../components/dashboard/DashboardElement.vue";

export default {
  name: "Requests",
  components: {
    Card,
    DashboardHead,
    DashboardTable,
    DashboardElement
  },
  data: () => {
    return {
      formData: null,
      comments: [],
      kunden: []
    };
  },
  computed: {
    requests() {
      return this.$store.state.anfragen.requests;
    }
  }
};
</script>
