<template>
  <div
    class="card"
    @drop="onDrop($event, status)"
    @dragover.prevent
    @dragenter.prevent
  >
    <div class="flex flex-col">
      <div
        class="mb-0.75 hover:bg-gray-50 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-700 hover:border-primary-700 border sm:cursor-pointer shadow-md border-gray-100 bg-white rounded-md p-4"
        draggable="true"
        @dragstart="startDrag($event, request)"
      >
        <router-link :to="`anfrage/${request.id}`">
          <div class="flex justify-between requests-center items-center">
            <div>
              <h3>
                {{
                  `${request.form} ${request.durchmesser}-${request.belagbreite}-${request.belaghoehe}-${request.gesamthoehe}-${request.bohrung}`
                }}

                <br />

                {{
                  `${request.korngroesse !== null ? request.korngroesse : ""} ${
                    request.korn !== null ? request.korn : ""
                  } ${
                    request.konzentration !== null ? request.konzentration : ""
                  } ${
                    request.bindungstyp !== null
                      ? request.bindung && request.korn
                        ? ""
                        : request.bindungstyp
                      : ""
                  } ${request.bindung !== null ? request.bindung : ""}`
                }}
              </h3>

              <div class="mt-2" v-if="request.anwendungen">
                <span
                  class="bg-primary-700 text-white text-sm rounded px-1.5 py-1 mr-1"
                  v-for="anwendung in request.anwendungen"
                  :key="anwendung"
                >
                  {{ anwendung }}
                </span>
              </div>
            </div>
            <div>
              <status-icon
                :status="request.status"
                :in-shop="request.a_in_shop"
              />
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import StatusIcon from "../components/StatusIcon";

export default {
  name: "RequestCard",
  components: {
    StatusIcon
  },
  methods: {
    startDrag: (evt, request) => {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("requestID", request.id);
    },
    onDrop(evt, status) {
      if (
        this.request.status === status ||
        (status === 1 && this.request.status > 0 && this.request.status < 3)
      ) {
        alert("So wird das heute nichts...");
      } else {
        this.request.status = status;
        this.request.updatedAt = new Date();
        this.updateItem(this.request);
      }
    },
    updateItem(request) {
      // TODO: Update Request
      console.log(request.id);
    },
    getCommentCount(id) {
      const currentComments =
        this.comments &&
        this.comments.filter(comment => comment.requestId === id);

      const notReadComments = currentComments.filter(
        comment =>
          comment.readby && comment.readby.includes(this.$auth.user.name)
      );

      return currentComments.length - notReadComments.length;
    },
    getCustomerName(customernumber) {
      let firstname, customergroup;

      this.customers.map(customer => {
        if (customer.customernumber === customernumber) {
          firstname = customer.company
            ? customer.company
            : customer.firstname + " " + customer.lastname;
          customergroup = customer.customergroup;
        }
      });

      return [firstname, customergroup];
    }
  },
  props: {
    loading: Boolean,
    customers: Array,
    abrasiveData: Object,
    request: Object,
    status: Number,
    comments: Array
  }
};
</script>
