<template>
  <div class="trafficlight-container">
    <div
      class="trafficlight"
      :class="{ 'trafficlight--blue': status === 0 }"
      title="Neu"
    >
      <icon-checkmark v-if="status > 0" />
    </div>
    <div
      class="trafficlight"
      :class="{ 'trafficlight--yellow': status === 1 }"
      title="In Bearbeitung"
    >
      <icon-checkmark v-if="status > 1" />
    </div>
    <div
      class="trafficlight"
      :class="{
        'trafficlight--red': status === 2,
        'trafficlight--green': status === 3
      }"
      title="Qualität bestimmen"
    >
      <span v-if="status === 2" style="color: white; font-size: 0.5rem"
        >MG</span
      >
      <span v-if="status === 3" style="color: white; font-size: 0.5rem"
        >VK</span
      >
      <icon-checkmark v-if="status === 4" />
    </div>
    <div
      class="trafficlight"
      :class="{ 'trafficlight--green': inShop }"
      title="Erledigt / Im Shop"
      v-if="status === 4"
    >
      <icon-checkmark v-if="status === 4" />
    </div>
  </div>
</template>

<script>
import IconCheckmark from "./icons/IconCheckmark.vue";

export default {
  components: { IconCheckmark },
  name: "StatusIcon",
  props: {
    status: Number,
    inShop: Boolean
  }
};
</script>

<style scoped lang="scss">
.trafficlight-container {
  display: flex;
  justify-content: center;
}

.trafficlight {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #333333;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &--red {
    background: crimson;
  }

  &--yellow {
    background: goldenrod;
  }

  &--green {
    background: mediumseagreen;
  }

  &--blue {
    background: dodgerblue;
  }

  &:last-child {
    margin-right: 0;
  }
}
</style>
